import { FilterItem } from 'components/multi-filter/multi-filter';
import { TableColumnProps } from '../../components/table/table-component/table-component';

export const tableStructure: TableColumnProps[] = [
  {
    id: 'title',
    title: 'Title',
    position: 'left',
  },
  {
    id: 'website_name',
    title: 'Website Name',
    position: 'left',
  },
  // {
  //   id: 'is_present',
  //   title: 'Present',
  //   position: 'left',
  // },
  // {
  //   id: 'is_addressed',
  //   title: 'Addressed',
  //   withSort: true,
  //   position: 'left',
  // },
  {
    id: 'email',
    title: 'User',
    position: 'left',
  },
  {
    id: 'state',
    title: 'State',
    withSort: true,
    position: 'left',
  },
  {
    id: 'severity',
    title: 'Severity',
    withSort: true,
    position: 'left',
  },
  {
    id: 'first_seen_date',
    title: 'First Seen',
    isDate: true,
    position: 'left',
  },
  {
    id: 'last_seen_date',
    title: 'Last Seen',
    isDate: true,
    position: 'left',
  },
  {
    id: 'causeMetadata',
    title: 'Details',
    position: 'left',
  },
];

export const DastFilterList: FilterItem[] = [
  {
    title: 'Severity',
    filterId: 'severity',
    filterColumns: ['severity'],
    props: [
      {
        label: 'Critical',
        value: 'Critical',
      },
      {
        label: 'High',
        value: 'High',
      },
      {
        label: 'Medium',
        value: 'Medium',
      },
      {
        label: 'Low',
        value: 'Low',
      },
      {
        label: 'Information',
        value: 'Information',
      },
      {
        label: 'Best Practice',
        value: 'BestPractice',
      },
    ],
  },
  {
    title: 'State',
    filterId: 'state',
    filterColumns: ['state'],
    props: [
      {
        label: 'Present',
        value: 'Present',
      },
      {
        label: 'FixedConfirmed',
        value: 'FixedConfirmed',
      },
      {
        label: 'Revived',
        value: 'Revived',
      },
      {
        label: 'FixedConfirmed, AcceptedRisk',
        value: 'FixedConfirmed, AcceptedRisk',
      },
      {
        label: 'Present, AcceptedRisk',
        value: 'Present, AcceptedRisk',
      },
    ],
  },
];
