export const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const splunkUrlPattern =
  /^https?:\/\/.[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&=]*)/;

export const partialUrl =
  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

export const oktaUrlPattern =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?).okta\.com$/;

export const atlassianUrlPattern =
  /^https:\/\/[a-zA-Z0-9._-]*\.atlassian\.net$/;

export const urlWithProtocol =
  /^https?:\/\/.[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&=]*)$/;

export const urlIPWithProtocol = /^https?:\/\/.[-a-zA-Z0-9@:%./_+~#=]{2,256}$/;

export const protocolSchema = /^(https?):\/\//;

export const wizUrlPattern =
  /^((https?):\/\/)(auth\.app\.wiz\.io)\/oauth\/token(\?.*)?$/;
